import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { SEO } from "../components/seo";
import ThemeContext from "../context/ThemeContext";

const BrandsPage = ({ data }) => {
  let cursorRef;
  const themeState = useContext(ThemeContext);
  const brands = data.prismicBrands.data;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState({
    id: currentIndex,
    ...brands.brand[currentIndex],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < brands.brand.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(0);
      }
    }, 4000);
    (async () => {
      setSelectedBrand({
        id: currentIndex,
        ...brands.brand[currentIndex],
      });
    })();
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {}, []);

  const changeBackground = (e) => {
    removeBorder();
    themeState.toggleCursorBackground("#fff");
    // themeState.scaleCursor(3.5, true);
  };
  // const scaleCursorDown = (e) => {
  //   themeState.toggleCursorBackground('transparent');
  //   themeState.scaleCursor(1);
  // }

  const resetCursor = (e) => {
    themeState.toggleCursorBlendMode();
    themeState.toggleCursorBackground("transparent");
    themeState.scaleCursor(1);
  };

  const shrinkCursor = (e) => {
    themeState.toggleCursorBlendMode("difference");
    themeState.toggleCursorBackground("#fff");
    themeState.shrinkCursor(0.5);
  };

  const removeBorder = () => {
    themeState.toggleCursorBorder();
  };
  const showBorder = () => {
    themeState.toggleCursorBorder("1px solid rgba(255,255,255,1)");
  };

  return (
    <section className="max-w-[1440px] mx-[17px] md:mx-auto flex mt-[20px] md:mt-[81px] md:pl-[34px] box-border">
      <SEO
        title="Brands - Morch"
        description="MØRCH IS A FASHION SALES AGENCY FOUNDED BY LOTTE MØRCH IN 1996 AND IS ONE OF THE LEADING FASHION AGENCIES IN SCANDINAVIA."
      />

      <div className="md:w-[196px]">
        <h4 className="uppercase">Brands</h4>
        <ul className="mt-2 min-w-[130px]">
          {brands.brand?.map((item, index) => {
            return (
              <li
                className="uppercase"
                key={index}
                onMouseEnter={shrinkCursor}
                onMouseLeave={resetCursor}
              >
                <button
                  className={`bg-transparent text-left ${
                    selectedBrand.id === index && "line-through"
                  }`}
                  onClick={() => setCurrentIndex(index)}
                >
                  {item.brand_name.text}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="image-container w-full  ml-0 mt-[18px] md:mt-0">
        <div
          className="h-full w-full md:h-[588px] md:w-[419px]"
          // onMouseEnter={changeBackground} onMouseLeave={resetCursor}
        >
          <GatsbyImage
            image={selectedBrand.brand_image.gatsbyImageData}
            alt={selectedBrand.brand_name.text}
            className="h-full w-full backdrop"
            objectFit="cover"
          />
          <div className="text-right">
            <a
              href={selectedBrand.brand_link}
              target="_blank"
              className="cursor-pointer no-underline"
              onMouseEnter={shrinkCursor}
              onMouseLeave={resetCursor}
            >
              <span className="lowercase leading-5 text-[8px] md:text-[10px]">
                {selectedBrand.brand_link}
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrandsPage;

export const query = graphql`
  {
    prismicBrands {
      data {
        brand {
          brand_name {
            text
          }
          brand_link
          brand_image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            alt
          }
        }
      }
    }
  }
`;
